import { Box, TextField } from "@mui/material";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Edit } from "@refinedev/mui";
import { useForm } from "@refinedev/react-hook-form";

import { ContentLoadingIndicator } from "@/components/information/ContentLoadingIndicator";

export const ProductEdit: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const {
    saveButtonProps,
    refineCore: { queryResult },
    register,
    formState: { errors },
  } = useForm();

  return (
    <Edit isLoading={queryResult?.isLoading} saveButtonProps={saveButtonProps}>
      {queryResult?.isLoading ? (
        <ContentLoadingIndicator fill />
      ) : (
        <Box component="form" sx={{ display: "flex", flexDirection: "column" }} autoComplete="off">
          <TextField
            {...register("id", {
              required: "This field is required",
              valueAsNumber: true,
            })}
            error={!!(errors as any)?.id}
            helperText={(errors as any)?.id?.message}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="number"
            label={translate("products.fields.id")}
            name="id"
            disabled
          />
          <TextField
            {...register("code", {
              required: "This field is required",
            })}
            error={!!(errors as any)?.code}
            helperText={(errors as any)?.code?.message}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="text"
            label={translate("products.fields.code")}
            name="code"
          />
          <TextField
            {...register("displayName", {
              required: "This field is required",
            })}
            error={!!(errors as any)?.displayName}
            helperText={(errors as any)?.displayName?.message}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="text"
            label={translate("products.fields.displayName")}
            name="displayName"
          />
          <TextField
            {...register("description", {
              required: "This field is required",
            })}
            error={!!(errors as any)?.description}
            helperText={(errors as any)?.description?.message}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="text"
            label={translate("products.fields.description")}
            name="description"
          />
        </Box>
      )}
    </Edit>
  );
};
