import { Autocomplete, Box, TextField } from "@mui/material";
import { IResourceComponentsProps, useOne, useResource, useTranslate } from "@refinedev/core";
import { Create, useAutocomplete } from "@refinedev/mui";
import { useForm } from "@refinedev/react-hook-form";
import { useMemo } from "react";
import { Controller } from "react-hook-form";
import { useMatch } from "react-router";

import { CustomerProductsBreadcrumbs } from "@/components/breadcrumbs";
import { ContentLoadingIndicator } from "@/components/information/ContentLoadingIndicator";

export const CustomerProductUserRoleCreate: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { resource } = useResource("customer-product-user-roles");
  const match = useMatch(`${resource?.create}`);
  const customer_id = match?.params.customer_id ?? "";
  const product_id = match?.params.product_id ?? "";
  const meta = useMemo(
    () => ({
      customer_id,
      product_id,
    }),
    [customer_id, product_id],
  );

  const { data: customer, isLoading: customerIsLoading } = useOne({
    resource: "customers",
    id: customer_id,
  });

  const { data: product, isLoading: productIsLoading } = useOne({
    resource: "products",
    id: product_id,
  });

  const {
    saveButtonProps,
    refineCore: { formLoading },
    control,
    formState: { errors },
  } = useForm({
    refineCoreProps: {
      resource: "customer-product-user-roles",
      meta,
    },
  });

  const { autocompleteProps: userAutocompleteProps } = useAutocomplete({
    resource: "customer-users",
    meta,
  });

  const { autocompleteProps: roleAutocompleteProps } = useAutocomplete({
    resource: "customer-product-roles",
    meta,
  });

  const isPending = formLoading || customerIsLoading || productIsLoading;

  return (
    <Create
      breadcrumb={
        <CustomerProductsBreadcrumbs
          customer={customer?.data}
          product={product?.data}
          lastPaths={[
            {
              id: "customer-product-roles",
              path: `/customers/${customer?.data.id}/products/${product?.data.id}/roles`,
              title: translate("roles.roles", "Roles"),
            },
          ]}
        />
      }
      isLoading={isPending}
      saveButtonProps={saveButtonProps}
    >
      {isPending ? (
        <ContentLoadingIndicator fill />
      ) : (
        <Box component="form" sx={{ display: "flex", flexDirection: "column" }} autoComplete="off">
          <Controller
            control={control}
            name="user"
            rules={{ required: "This field is required" }}
            defaultValue={null}
            render={({ field }) => (
              <Autocomplete
                {...userAutocompleteProps}
                {...field}
                onChange={(_, value) => {
                  field.onChange(value);
                }}
                getOptionKey={(item) => {
                  return `key-role-${item.id}`;
                }}
                getOptionLabel={(item) => {
                  return item.name;
                }}
                isOptionEqualToValue={(option, value) => {
                  const isEqual = option.id === value.id;
                  return isEqual;
                }}
                renderInput={(params) => (
                  <TextField
                    // sx={{ width: 250 }}
                    {...params}
                    label={translate("customer-product-user-roles.fields.associatedUser")}
                    margin="normal"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    error={!!(errors as any)?.title}
                    helperText={(errors as any)?.title?.message}
                    required
                  />
                )}
              />
            )}
          />
          <Controller
            control={control}
            name="role"
            rules={{ required: "This field is required" }}
            defaultValue={null}
            render={({ field }) => (
              <Autocomplete
                {...roleAutocompleteProps}
                {...field}
                onChange={(_, value) => {
                  field.onChange(value);
                }}
                getOptionKey={(item) => {
                  return `key-role-${item.id}`;
                }}
                getOptionLabel={(item) => {
                  return item.name;
                }}
                isOptionEqualToValue={(option, value) => {
                  const isEqual = option.id === value.id;
                  return isEqual;
                }}
                renderInput={(params) => (
                  <TextField
                    // sx={{ width: 250 }}
                    {...params}
                    label={translate("customer-product-user-roles.fields.associatedRole")}
                    margin="normal"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    error={!!(errors as any)?.product?.title}
                    helperText={(errors as any)?.product?.title?.message}
                    required
                  />
                )}
              />
            )}
          />
        </Box>
      )}
    </Create>
  );
};
