import { useAuth0 } from "@auth0/auth0-react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { useTranslate } from "@refinedev/core";
import { ThemedTitleV2 } from "@refinedev/mui";

import { AppIcon } from "../components/app-icon";

export const Login: React.FC = () => {
  const { loginWithRedirect } = useAuth0();

  const t = useTranslate();

  return (
    <Container
      style={{
        height: "100vh",
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box display="flex" gap="36px" justifyContent="center" alignItems="center" flexDirection="column">
        <AppIcon size={48} fill={"#D32F2F"} />
        <ThemedTitleV2
          collapsed={false}
          wrapperStyles={{
            fontSize: "22px",
            justifyContent: "center",
          }}
          text={t("product.title", "Competition Solutions Permissions Manager")}
          icon={null}
        />

        <Button style={{ width: "240px" }} size="large" variant="contained" onClick={() => loginWithRedirect()}>
          {t("pages.login.signin", "Sign in")}
        </Button>
      </Box>
    </Container>
  );
};
