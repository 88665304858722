import { ButtonGroup } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { DateField, List, ShowButton, useDataGrid } from "@refinedev/mui";
import React from "react";

import { DATE_TIME_FORMAT } from "@/Environment";

export const UserList: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { dataGridProps } = useDataGrid();

  const columns = React.useMemo<GridColDef[]>(
    () => [
      {
        field: "actions",
        headerName: translate("table.actions"),
        sortable: false,
        renderCell: function render({ row }) {
          return (
            <ButtonGroup className="ListActions" color="info" variant="outlined" size="small">
              <ShowButton hideText recordItemId={row.id} />
            </ButtonGroup>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 140,
        maxWidth: 180,
      },
      {
        field: "id",
        headerName: translate("users.fields.id"),
        type: "number",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        field: "identityProviderId",
        headerName: translate("users.fields.identityProviderId"),
        minWidth: 350,
      },
      {
        field: "email",
        flex: 1,
        headerName: translate("users.fields.email"),
        maxWidth: 280,
      },
      {
        field: "name",
        flex: 1,
        headerName: translate("users.fields.name"),
        minWidth: 200,
      },
      {
        field: "type",
        flex: 1,
        headerName: translate("users.fields.type"),
        minWidth: 200,
      },
      {
        field: "updatedOn",
        flex: 1,
        headerName: translate("users.fields.updatedOn"),
        maxWidth: 180,
        renderCell: function render({ value }) {
          return <DateField className="DateTimeFullCell" value={value} format={DATE_TIME_FORMAT} />;
        },
      },
    ],
    [translate],
  );

  return (
    <List>
      <DataGrid {...dataGridProps} columns={columns} autoHeight />
    </List>
  );
};
