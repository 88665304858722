import { Box, TextField } from "@mui/material";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Create } from "@refinedev/mui";
import { useForm } from "@refinedev/react-hook-form";

export const CustomerCreate: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const {
    saveButtonProps,
    refineCore: { formLoading },
    register,
    formState: { errors },
  } = useForm();

  return (
    <Create isLoading={formLoading} saveButtonProps={saveButtonProps}>
      <Box component="form" sx={{ display: "flex", flexDirection: "column" }} autoComplete="off">
        <TextField
          {...register("code", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.code}
          helperText={(errors as any)?.code?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={translate("customers.fields.code")}
          name="code"
        />
        <TextField
          {...register("displayName", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.displayName}
          helperText={(errors as any)?.displayName?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={translate("customers.fields.displayName")}
          name="displayName"
        />
        <TextField
          {...register("description", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.description}
          helperText={(errors as any)?.description?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={translate("customers.fields.description")}
          name="description"
        />
        <TextField
          {...register("contactName", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.contactName}
          helperText={(errors as any)?.contactName?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={translate("customers.fields.contactName")}
          name="contactName"
        />
        <TextField
          {...register("contactEmail", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.contactEmail}
          helperText={(errors as any)?.contactEmail?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={translate("customers.fields.contactEmail")}
          name="contactEmail"
        />
      </Box>
    </Create>
  );
};
