import { SvgIconProps, SvgIconTypeMap } from "@mui/material";
import type { ButtonProps } from "@mui/material/Button";
import Button from "@mui/material/Button";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import {
  AccessControlContext,
  BaseKey,
  IResourceItem,
  useCan,
  useGo,
  useLink,
  useResource,
  useTranslate,
} from "@refinedev/core";
import {
  RefineButtonCommonProps,
  RefineButtonLinkingProps,
  RefineButtonResourceProps,
  RefineButtonSingleProps,
} from "@refinedev/ui-types";
import { RefineButtonURLProps } from "@refinedev/ui-types/src/types/button";
import React, { useContext } from "react";

export type RefineListActionButtonProps<
  TComponentProps extends {} = Record<string, unknown>,
  TExtraProps extends {} = {},
> = RefineButtonCommonProps &
  RefineButtonResourceProps &
  RefineButtonLinkingProps &
  RefineButtonURLProps &
  RefineButtonSingleProps &
  TComponentProps &
  TExtraProps & {};

export type ListActionButtonProps = RefineListActionButtonProps<
  ButtonProps,
  {
    IconComponent:
      | (OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
          muiName: string;
        })
      | undefined;
    svgIconProps?: SvgIconProps;
    customActionUrl?: string;
  }
>;

/**
 * `<ListActionButton>` uses Material UI {@link https://mui.com/components/buttons/ `<Button>`} component.
 * It uses the {@link https://refine.dev/docs/api-reference/core/hooks/navigation/useNavigation#show `show`} method from {@link https://refine.dev/docs/api-reference/core/hooks/navigation/useNavigation `useNavigation`} under the hood.
 * It can be useful when redirecting the app to the show page with the record id route of resource.
 *
 * @see {@link https://refine.dev/docs/api-reference/mui/components/buttons/show-button} for more details.
 */
export const ListActionButton: React.FC<ListActionButtonProps> = ({
  resource: resourceNameFromProps,
  recordItemId,
  hideText = false,
  accessControl,
  svgIconProps,
  meta,
  children,
  onClick,
  customActionUrl,
  IconComponent,
  ...rest
}) => {
  const accessControlContext = useContext(AccessControlContext);
  const go = useGo();
  const accessControlEnabled = accessControl?.enabled ?? accessControlContext.options.buttons.enableAccessControl;
  const hideIfUnauthorized =
    accessControl?.hideIfUnauthorized ?? accessControlContext.options.buttons.hideIfUnauthorized;
  const Link: any = useLink();
  const translate = useTranslate();

  const { id, resource } = useResource(resourceNameFromProps);

  const listUrl = (resource: string | IResourceItem, id: BaseKey | undefined) => {
    return go({
      to: customActionUrl,
      type: "path",
    }) as string;
  };

  const { data } = useCan({
    resource: resource?.name,
    action: customActionUrl ?? "list",
    params: { id: recordItemId ?? id, resource },
    queryOptions: {
      enabled: accessControlEnabled,
    },
  });

  const disabledTitle = () => {
    if (data?.can) return "";
    if (data?.reason) return data.reason;
    return translate("buttons.notAccessTitle", "You don't have permission to access");
  };

  const actionUrl = resource && (recordItemId || id) ? listUrl(resource, recordItemId || id) : "";

  const { sx, ...restProps } = rest;

  if (accessControlEnabled && hideIfUnauthorized && !data?.can) {
    return null;
  }

  return (
    <Link
      to={actionUrl}
      replace={false}
      onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (data?.can === false) {
          e.preventDefault();
          return;
        }
        if (onClick) {
          e.preventDefault();
          onClick(e);
        }
      }}
      style={{ textDecoration: "none" }}
    >
      <Button
        disabled={data?.can === false}
        startIcon={!hideText && (IconComponent ? <IconComponent {...svgIconProps} /> : null)}
        title={disabledTitle()}
        sx={{ minWidth: 0, ...sx }}
        data-testid="list-action-button"
        className="list-action-button"
        {...restProps}
      >
        {hideText ? (
          IconComponent ? (
            <IconComponent fontSize="small" {...svgIconProps} />
          ) : null
        ) : (
          (children ?? translate("buttons.show", "Show"))
        )}
      </Button>
    </Link>
  );
};
