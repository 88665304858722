import { GraphicEqOutlined } from "@mui/icons-material";
import ButtonGroup from "@mui/material/ButtonGroup";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { DateField, DeleteButton, EditButton, List, MarkdownField, ShowButton, useDataGrid } from "@refinedev/mui";
import React from "react";

import { DATE_TIME_FORMAT } from "@/Environment";
import { ListActionButton } from "@/components/buttons/ListActionButton";

export const ProductList: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { dataGridProps } = useDataGrid();

  const columns = React.useMemo<GridColDef[]>(
    () => [
      {
        field: "actions",
        headerName: translate("table.actions"),
        sortable: false,
        renderCell: function render({ row }) {
          return (
            <ButtonGroup className="ListActions" color="info" variant="outlined" size="small">
              <EditButton hideText recordItemId={row.id} />
              <ShowButton hideText recordItemId={row.id} />
              <ListActionButton
                recordItemId={row.id}
                customActionUrl={`/products/${row.id}/permission-model`}
                IconComponent={GraphicEqOutlined}
                hideText
                meta={{
                  product_id: row.id,
                }}
              />
              <DeleteButton hideText recordItemId={row.id} />
            </ButtonGroup>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 190,
        maxWidth: 190,
      },
      {
        field: "id",
        headerName: translate("products.fields.id"),
        type: "number",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        field: "code",
        flex: 1,
        headerName: translate("products.fields.code"),
        maxWidth: 200,
      },
      {
        field: "displayName",
        flex: 1,
        headerName: translate("products.fields.displayName"),
        minWidth: 200,
      },
      {
        field: "description",
        flex: 1,
        headerName: translate("products.fields.description"),
        minWidth: 250,
        sortable: false,
        renderCell: function render({ value }) {
          return <MarkdownField value={(value ?? "").slice(0, 80) + "..."} />;
        },
      },
      {
        field: "updatedOn",
        flex: 1,
        headerName: translate("products.fields.updatedOn"),
        maxWidth: 180,
        renderCell: function render({ value }) {
          return <DateField className="DateTimeFullCell" value={value} format={DATE_TIME_FORMAT} />;
        },
      },
    ],
    [translate],
  );

  return (
    <List>
      <DataGrid {...dataGridProps} columns={columns} autoHeight />
    </List>
  );
};
