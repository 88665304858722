import { CircularProgress } from "@mui/material";
import React from "react";

import "./ContentLoadingIndicator.css";

export interface ContentLoadingIndicatorProps {
  fill?: boolean;
}

export const ContentLoadingIndicator: React.FC<ContentLoadingIndicatorProps> = ({ fill }) => {
  return (
    <div className="ContentLoadingIndicator" data-fill={fill ? "yes" : "no"}>
      <CircularProgress />
    </div>
  );
};
