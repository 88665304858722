import {
  CreateParams,
  DataProvider,
  GetListParams,
  GetOneParams,
  HttpError,
  LogicalFilter,
  UpdateParams,
} from "@refinedev/core";
import axios from "axios";
import { stringify } from "query-string";

import { CSPM_API_BASE_URL } from "@/Environment";

export async function performTokenExchange(token: string) {
  const serviceUrl = `${CSPM_API_BASE_URL}/v1/token/exchange`;
  const res = await axios.post(serviceUrl, { accessToken: token });
  return res.data.sessionToken;
}

export function getSearchTermFromParams(params: any) {
  const searchTerm: LogicalFilter | unknown | undefined = Array.from(params.filters ?? ([] as LogicalFilter[])).find(
    (t: unknown, index: number, obj: unknown[]) => (t as LogicalFilter).field === "title",
  );
  return (searchTerm as LogicalFilter)?.value ?? "";
}

// Error handling with axios interceptors
export const axiosInstance = axios.create();
axiosInstance.defaults.headers.common = {
  Authorization: `Bearer ${sessionStorage.getItem("session.token.value")}`,
};

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const customError: HttpError = {
      ...error,
      message: error.response?.data?.message,
      statusCode: error.response?.status,
    };

    return Promise.reject(customError);
  },
);

export const Resources: { [key: string]: any } = {
  // Customers
  customers: {
    getList: async (params: GetListParams) => {
      const url = `${CSPM_API_BASE_URL}/v1/customers`;
      const { current = 1, pageSize = 25 } = params.pagination ?? {};
      const query: {
        offset?: number;
        limit?: number;
        search?: string;
      } = {
        offset: (current - 1) * pageSize,
        limit: pageSize,
      };
      const searchTerm = getSearchTermFromParams(params);
      if (searchTerm !== undefined) {
        query.search = searchTerm;
      }
      const { data, headers } = await axiosInstance.get(`${url}?${stringify(query)}`);
      const total = +headers["x-total-count"];
      return {
        data,
        total,
      };
    },
    create: async ({ variables, meta }: CreateParams) => {
      const url = `${CSPM_API_BASE_URL}/v1/customers`;
      const { data } = await axiosInstance.post(url, variables);
      return {
        data,
      };
    },
    update: async ({ resource, id, variables, meta }: UpdateParams) => {
      const url = `${CSPM_API_BASE_URL}/v1/customers/${id}`;
      const { data } = await axiosInstance.put(url, {
        ...variables,
        id: undefined,
      });
      return {
        data,
      };
    },
    getOne: async ({ resource, id, meta }: GetOneParams) => {
      const url = `${CSPM_API_BASE_URL}/v1/customers/${id}`;
      const { data } = await axiosInstance.get(url);
      return {
        data,
      };
    },
    deleteOne: async ({ resource, id, variables, meta }: UpdateParams) => {
      const url = `${CSPM_API_BASE_URL}/v1/customers/${id}`;
      const { data } = await axiosInstance.delete(url);
      return {
        data,
      };
    },
  },
  // Products
  products: {
    getList: async (params: GetListParams) => {
      const url = `${CSPM_API_BASE_URL}/v1/products`;
      const { current = 1, pageSize = 25 } = params.pagination ?? {};
      const query: {
        offset?: number;
        limit?: number;
        search?: string;
      } = {
        offset: (current - 1) * pageSize,
        limit: pageSize,
      };
      const searchTerm = getSearchTermFromParams(params);
      if (searchTerm !== undefined) {
        query.search = searchTerm;
      }
      const { data, headers } = await axiosInstance.get(`${url}?${stringify(query)}`);
      const total = +headers["x-total-count"];
      return {
        data,
        total,
      };
    },
    create: async ({ variables, meta }: CreateParams) => {
      const url = `${CSPM_API_BASE_URL}/v1/products`;
      const { data } = await axiosInstance.post(url, variables);
      return {
        data,
      };
    },
    update: async ({ resource, id, variables, meta }: UpdateParams) => {
      const url = `${CSPM_API_BASE_URL}/v1/products/${id}`;
      const { data } = await axiosInstance.put(url, {
        ...variables,
        id: undefined,
      });
      return {
        data,
      };
    },
    getOne: async ({ resource, id, meta }: GetOneParams) => {
      const url = `${CSPM_API_BASE_URL}/v1/products/${id}`;
      const { data } = await axiosInstance.get(url);
      return {
        data,
      };
    },
  },
  // Roles
  roles: {
    getList: async (params: GetListParams) => {
      const url = `${CSPM_API_BASE_URL}/v1/roles`;
      const { current = 1, pageSize = 25 } = params.pagination ?? {};
      const query: {
        offset?: number;
        limit?: number;
        search?: string;
      } = {
        offset: (current - 1) * pageSize,
        limit: pageSize,
      };
      const searchTerm = getSearchTermFromParams(params);
      if (searchTerm !== undefined) {
        query.search = searchTerm;
      }
      const { data, headers } = await axiosInstance.get(`${url}?${stringify(query)}`);
      const total = +headers["x-total-count"];
      return {
        data,
        total,
      };
    },
    create: async ({ variables, meta }: CreateParams) => {
      const url = `${CSPM_API_BASE_URL}/v1/roles`;
      const { data } = await axiosInstance.post(url, variables);
      return {
        data,
      };
    },
    update: async ({ resource, id, variables, meta }: UpdateParams) => {
      const url = `${CSPM_API_BASE_URL}/v1/roles/${id}`;
      const { data } = await axiosInstance.put(url, {
        ...variables,
        id: undefined,
      });
      return {
        data,
      };
    },
    getOne: async ({ resource, id, meta }: GetOneParams) => {
      const url = `${CSPM_API_BASE_URL}/v1/roles/${id}`;
      const { data } = await axiosInstance.get(url);
      return {
        data,
      };
    },
  },
  // Users
  users: {
    getList: async (params: GetListParams) => {
      const url = `${CSPM_API_BASE_URL}/v1/users`;
      const { current = 1, pageSize = 25 } = params.pagination ?? {};
      const query: {
        offset?: number;
        limit?: number;
        search?: string;
      } = {
        offset: (current - 1) * pageSize,
        limit: pageSize,
      };
      const searchTerm = getSearchTermFromParams(params);
      if (searchTerm !== undefined) {
        query.search = searchTerm;
      }
      const { data, headers } = await axiosInstance.get(`${url}?${stringify(query)}`);
      const total = +headers["x-total-count"];
      return {
        data,
        total,
      };
    },
    create: async ({ variables, meta }: CreateParams) => {
      const url = `${CSPM_API_BASE_URL}/v1/users`;
      const { data } = await axiosInstance.post(url, variables);
      return {
        data,
      };
    },
    update: async ({ resource, id, variables, meta }: UpdateParams) => {
      const url = `${CSPM_API_BASE_URL}/v1/users/${id}`;
      const { data } = await axiosInstance.put(url, {
        ...variables,
        id: undefined,
      });
      return {
        data,
      };
    },
    getOne: async ({ resource, id, meta }: GetOneParams) => {
      const url = `${CSPM_API_BASE_URL}/v1/users/${id}`;
      const { data } = await axiosInstance.get(url);
      return {
        data,
      };
    },
  },
  // Relations
  "customer-users": {
    getList: async (params: GetListParams) => {
      const url = `${CSPM_API_BASE_URL}/v1/customers/${params.meta?.customer_id}/users`;
      const { current = 1, pageSize = 25 } = params.pagination ?? {};
      const query: {
        offset?: number;
        limit?: number;
        search?: string;
      } = {
        offset: (current - 1) * pageSize,
        limit: pageSize,
      };
      const searchTerm = getSearchTermFromParams(params);
      if (searchTerm !== undefined) {
        query.search = searchTerm;
      }
      const { data, headers } = await axiosInstance.get(`${url}?${stringify(query)}`);
      const total = +headers["x-total-count"];
      return {
        data,
        total,
      };
    },
    create: async ({ variables, meta }: CreateParams) => {
      const url = `${CSPM_API_BASE_URL}/v1/customers/${meta?.customer_id}/users`;
      const { data } = await axiosInstance.post(url, {
        userId: (variables as any).user.id,
      });
      return {
        data,
      };
    },
    deleteOne: async ({ resource, id, variables, meta }: UpdateParams) => {
      const url = `${CSPM_API_BASE_URL}/v1/customers/${meta?.customer_id}/users/${id}`;
      const { data } = await axiosInstance.delete(url);
      return {
        data,
      };
    },
  },
  "customer-products": {
    getList: async (params: GetListParams) => {
      const url = `${CSPM_API_BASE_URL}/v1/customers/${params.meta?.id}/products`;
      const { current = 1, pageSize = 25 } = params.pagination ?? {};
      const query: {
        offset?: number;
        limit?: number;
        search?: string;
      } = {
        offset: (current - 1) * pageSize,
        limit: pageSize,
      };
      const searchTerm = getSearchTermFromParams(params);
      if (searchTerm !== undefined) {
        query.search = searchTerm;
      }
      const { data, headers } = await axiosInstance.get(`${url}?${stringify(query)}`);
      const total = +headers["x-total-count"];
      return {
        data,
        total,
      };
    },
    create: async ({ variables, meta }: CreateParams) => {
      const url = `${CSPM_API_BASE_URL}/v1/customers/${meta?.id}/products`;
      const { data } = await axiosInstance.post(url, {
        productId: (variables as any).product.id,
      });
      return {
        data,
      };
    },
    update: async ({ resource, id, variables, meta }: UpdateParams) => {
      const url = `${CSPM_API_BASE_URL}/v1/products/${id}`;
      const { data } = await axiosInstance.put(url, {
        ...variables,
        id: undefined,
      });
      return {
        data,
      };
    },
    getOne: async ({ resource, id, meta }: GetOneParams) => {
      const url = `${CSPM_API_BASE_URL}/v1/products/${id}`;
      const { data } = await axiosInstance.get(url);
      return {
        data,
      };
    },
    deleteOne: async ({ resource, id, variables, meta }: UpdateParams) => {
      const url = `${CSPM_API_BASE_URL}/v1/customers/${meta?.id}/products/${id}`;
      const { data } = await axiosInstance.delete(url);
      return {
        data,
      };
    },
  },
  "customer-product-permissions": {
    getList: async (params: GetListParams) => {
      const url = `${CSPM_API_BASE_URL}/v1/customers/${params.meta?.customer_id}/products/${params.meta?.id}/permissions`;
      const { current = 1, pageSize = 25 } = params.pagination ?? {};
      const query: {
        offset?: number;
        limit?: number;
        search?: string;
        orderBy?: string;
      } = {
        offset: (current - 1) * pageSize,
        limit: pageSize,
      };
      const sorters = params.sorters || [];
      if (sorters.length) {
        const orderBy: string[] = sorters.map((it) => {
          return `${it.field}:${it.order}`;
        });
        query.orderBy = orderBy.join(",");
      }

      const searchTerm = getSearchTermFromParams(params);
      if (searchTerm !== undefined) {
        query.search = searchTerm;
      }
      const { data, headers } = await axiosInstance.get(`${url}?${stringify(query)}`);
      const total = +headers["x-total-count"];
      return {
        data,
        total,
      };
    },
    create: async ({ variables, meta }: CreateParams) => {
      const url = `${CSPM_API_BASE_URL}/v1/admin/customers/${meta?.customer_id}/products/${meta?.product_id}/permissions`;
      const { data } = await axiosInstance.post(url, variables);
      return {
        data,
      };
    },
    update: async ({ resource, id, variables, meta }: UpdateParams) => {
      const url = `${CSPM_API_BASE_URL}/v1/admin/customers/${meta?.customer_id}/products/${meta?.product_id}/permissions/${id}`;
      const { data } = await axiosInstance.put(url, {
        ...variables,
        id: undefined,
      });
      return {
        data,
      };
    },
    getOne: async ({ resource, id, meta }: GetOneParams) => {
      const url = `${CSPM_API_BASE_URL}/v1/customers/${meta?.customer_id}/products/${meta?.product_id}/permissions/${id}`;
      const { data } = await axiosInstance.get(url);
      return {
        data,
      };
    },
    deleteOne: async ({ resource, id, variables, meta }: UpdateParams) => {
      const url = `${CSPM_API_BASE_URL}/v1/admin/customers/${meta?.customer_id}/products/${meta?.product_id}/permissions/${id}`;
      const { data } = await axiosInstance.delete(url);
      return {
        data,
      };
    },
  },
  "customer-product-permission-templates": {
    getList: async (params: GetListParams) => {
      const url = `${CSPM_API_BASE_URL}/v1/customers/${params.meta?.customer_id}/products/${params.meta?.id}/permissions/templates`;
      const { current = 1, pageSize = 25 } = params.pagination ?? {};
      const query: {
        offset?: number;
        limit?: number;
        search?: string;
      } = {
        offset: (current - 1) * pageSize,
        limit: pageSize,
      };
      const searchTerm = getSearchTermFromParams(params);
      if (searchTerm !== undefined) {
        query.search = searchTerm;
      }
      const { data, headers } = await axiosInstance.get(`${url}?${stringify(query)}`);
      const total = +headers["x-total-count"];
      return {
        data,
        total,
      };
    },
    create: async ({ variables, meta }: CreateParams) => {
      const url = `${CSPM_API_BASE_URL}/v1/customers/${meta?.customer_id}/products/${meta?.product_id}/permissions/templates`;
      const { data } = await axiosInstance.post(url, {
        name: (variables as any).name,
        description: (variables as any).description,
      });
      return {
        data,
      };
    },
    update: async ({ resource, id, variables, meta }: UpdateParams) => {
      const url = `${CSPM_API_BASE_URL}/v1/customers/${meta?.customer_id}/products/${meta?.product_id}/permissions/templates/${id}`;
      const { data } = await axiosInstance.put(url, {
        ...variables,
        id: undefined,
      });
      return {
        data,
      };
    },
    getOne: async ({ resource, id, meta }: GetOneParams) => {
      const url = `${CSPM_API_BASE_URL}/v1/customers/${meta?.customer_id}/products/${meta?.product_id}/permissions/templates/${id}`;
      const { data } = await axiosInstance.get(url);
      return {
        data,
      };
    },
  },
  "customer-product-roles": {
    getList: async (params: GetListParams) => {
      const url = `${CSPM_API_BASE_URL}/v1/customers/${params.meta?.customer_id}/products/${params.meta?.id}/roles`;
      const { current = 1, pageSize = 25 } = params.pagination ?? {};
      const query: {
        offset?: number;
        limit?: number;
        search?: string;
      } = {
        offset: (current - 1) * pageSize,
        limit: pageSize,
      };
      const searchTerm = getSearchTermFromParams(params);
      if (searchTerm !== undefined) {
        query.search = searchTerm;
      }
      const { data, headers } = await axiosInstance.get(`${url}?${stringify(query)}`);
      const total = +headers["x-total-count"];
      return {
        data,
        total,
      };
    },
    create: async ({ variables, meta }: CreateParams) => {
      const url = `${CSPM_API_BASE_URL}/v1/customers/${meta?.customer_id}/products/${meta?.product_id}/roles`;
      const { data } = await axiosInstance.post(url, {
        name: (variables as any).name,
        description: (variables as any).description,
      });
      return {
        data,
      };
    },
    update: async ({ resource, id, variables, meta }: UpdateParams) => {
      const url = `${CSPM_API_BASE_URL}/v1/customers/${meta?.customer_id}/products/${meta?.product_id}/roles/${id}`;
      const { data } = await axiosInstance.put(url, {
        ...variables,
        id: undefined,
      });
      return {
        data,
      };
    },
    getOne: async ({ resource, id, meta }: GetOneParams) => {
      const url = `${CSPM_API_BASE_URL}/v1/customers/${meta?.customer_id}/products/${meta?.product_id}/roles/${id}`;
      const { data } = await axiosInstance.get(url);
      return {
        data,
      };
    },
    deleteOne: async ({ resource, id, variables, meta }: UpdateParams) => {
      const url = `${CSPM_API_BASE_URL}/v1/customers/${meta?.customer_id}/products/${meta?.product_id}/roles/${id}`;
      const { data } = await axiosInstance.delete(url);
      return {
        data,
      };
    },
  },
  "customer-product-user-roles": {
    getList: async (params: GetListParams) => {
      const url = `${CSPM_API_BASE_URL}/v1/customers/${params.meta?.customer_id}/products/${params.meta?.product_id}/user/roles`;
      const { current = 1, pageSize = 25 } = params.pagination ?? {};
      const query: {
        offset?: number;
        limit?: number;
        search?: string;
      } = {
        offset: (current - 1) * pageSize,
        limit: pageSize,
      };
      const searchTerm = getSearchTermFromParams(params);
      if (searchTerm !== undefined) {
        query.search = searchTerm;
      }
      const { data, headers } = await axiosInstance.get(`${url}?${stringify(query)}`);
      const total = +headers["x-total-count"];
      return {
        data,
        total,
      };
    },
    create: async ({ variables, meta }: CreateParams) => {
      const { user, role } = variables as any;
      const url = `${CSPM_API_BASE_URL}/v1/customers/${meta?.customer_id}/products/${meta?.product_id}/users/${user.id}/roles`;
      const { data } = await axiosInstance.post(url, {
        roleId: role.id,
      });
      return {
        data,
      };
    },
    update: async ({ resource, id, variables, meta }: UpdateParams) => {
      const url = `${CSPM_API_BASE_URL}/v1/customers/${meta?.customer_id}/products/${meta?.product_id}/users/${meta?.user_id}/roles/${id}`;
      const { data } = await axiosInstance.put(url, {
        ...variables,
        id: undefined,
      });
      return {
        data,
      };
    },
    getOne: async ({ resource, id, meta }: GetOneParams) => {
      const url = `${CSPM_API_BASE_URL}/v1/customers/${meta?.customer_id}/products/${meta?.product_id}/users/${meta?.user_id}/roles/${id}`;
      const { data } = await axiosInstance.get(url);
      return {
        data,
      };
    },
    deleteOne: async ({ resource, id, variables, meta }: UpdateParams) => {
      const url = `${CSPM_API_BASE_URL}/v1/customers/${meta?.customer_id}/products/${meta?.product_id}/users/${meta?.user_id}/roles/${id}`;
      const { data } = await axiosInstance.delete(url);
      return {
        data,
      };
    },
  },
  "product-permission-model": {
    getOne: async ({ resource, id, meta }: GetOneParams) => {
      const url = `${CSPM_API_BASE_URL}/v1/products/${id ?? meta?.product_id}/model`;
      try {
        const { data } = await axiosInstance.get(url);
        return {
          exists: true,
          data,
        };
      } catch (error: any) {
        if (error.statusCode === 404) {
          return {
            exists: false,
            data: null,
          };
        }
        throw error;
      }
    },
    create: async ({ variables, meta }: CreateParams) => {
      const url = `${CSPM_API_BASE_URL}/v1/products/${meta?.product_id}/model`;
      const { data } = await axiosInstance.post(url, variables);
      return {
        data,
      };
    },
    update: async ({ variables, meta }: CreateParams) => {
      const url = `${CSPM_API_BASE_URL}/v1/products/${meta?.product_id}/model`;
      const { data } = await axiosInstance.put(url, {
        ...variables,
        product_id: undefined,
      });
      return {
        data,
      };
    },
  },
};

export const permissionManagerApiDataProvider: DataProvider = {
  // required methods
  getList: async ({ resource, pagination, sorters, filters, meta }) => {
    console.debug("Get resource list", {
      resource,
      pagination,
      sorters,
      filters,
      meta,
    });
    if (Resources[resource]) {
      return Resources[resource].getList({
        resource,
        pagination,
        sorters,
        filters,
        meta,
      });
    }
    return { data: [], total: 0 };
  },
  create: async ({ resource, variables, meta }) => {
    console.debug("Create resource", { resource, variables, meta });
    if (Resources[resource]) {
      return Resources[resource].create({
        resource,
        variables,
        meta,
      });
    }
    return {
      data: {} as any,
    };
  },
  update: async ({ resource, id, variables, meta }) => {
    console.debug("Update resource", { resource, id, variables, meta });
    if (Resources[resource]) {
      return Resources[resource].update({
        resource,
        id,
        variables,
        meta,
      });
    }
    return {
      data: {} as any,
    };
  },
  deleteOne: async ({ resource, id, variables, meta }) => {
    console.debug("Delete resource", { resource, id, variables, meta });
    if (Resources[resource]) {
      return Resources[resource].deleteOne({
        resource,
        id,
        variables,
        meta,
      });
    }
    return {
      data: {} as any,
    };
  },
  getOne: async ({ resource, id, meta }) => {
    console.debug("Get one resource", { resource, id, meta });
    if (Resources[resource]) {
      return Resources[resource].getOne({
        resource,
        id,
        meta,
      });
    }
    return {
      data: {} as any,
    };
  },
  getApiUrl: () => "",
};
