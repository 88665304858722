import { Box } from "@mui/material";
import { IResourceComponentsProps, useResource } from "@refinedev/core";
import { Edit } from "@refinedev/mui";
import { useForm } from "@refinedev/react-hook-form";
import { useMemo } from "react";
import { Controller } from "react-hook-form";

import { AdvancedEditor } from "@/components/editors/AdvancedEditor";
import { ContentLoadingIndicator } from "@/components/information/ContentLoadingIndicator";

export const ProductPermissionModelEdit: React.FC<IResourceComponentsProps> = () => {
  const { id } = useResource();
  const meta = useMemo(
    () => ({
      product_id: id,
    }),
    [id],
  );
  const {
    saveButtonProps,
    refineCore: { queryResult },
    control,
  } = useForm({
    refineCoreProps: {
      resource: "product-permission-model",
      meta,
      queryOptions: {
        retry: false,
      },
    },
  });

  const isPending = !!queryResult?.isLoading;

  return (
    <Edit isLoading={isPending} saveButtonProps={saveButtonProps}>
      {isPending ? (
        <ContentLoadingIndicator fill />
      ) : (
        <Box component="form" sx={{ display: "flex", flexDirection: "column" }} autoComplete="off">
          <Controller
            control={control}
            name="permissionModel"
            rules={{ required: "This field is required" }}
            defaultValue={null}
            render={(props) => {
              return (
                <AdvancedEditor
                  readOnly={isPending}
                  value={queryResult?.data?.data.permissionModel ?? ""}
                  onChange={(value: string) => {
                    props.field.onChange(value);
                  }}
                />
              );
            }}
          />
        </Box>
      )}
    </Edit>
  );
};
