import { VisibilityOutlined } from "@mui/icons-material";
import { ButtonGroup } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { IResourceComponentsProps, useOne, useResource, useTranslate } from "@refinedev/core";
import { DateField, DeleteButton, List, useDataGrid } from "@refinedev/mui";
import React, { useMemo } from "react";

import { DATE_TIME_FORMAT } from "@/Environment";
import { CustomerBreadcrumbs } from "@/components/breadcrumbs";
import { ListActionButton } from "@/components/buttons/ListActionButton";
import { ContentLoadingIndicator } from "@/components/information/ContentLoadingIndicator";

export const CustomerUserList: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { id } = useResource();
  const { data: customer, isLoading: customerIsLoading } = useOne({
    resource: "customers",
    id,
  });
  const meta = useMemo(() => ({ customer_id: id }), [id]);
  const { dataGridProps } = useDataGrid({
    meta,
  });

  const columns = React.useMemo<GridColDef[]>(
    () => [
      {
        field: "actions",
        headerName: translate("table.actions"),
        sortable: false,
        renderCell: function render({ row }) {
          return (
            <ButtonGroup className="ListActions" color="info" variant="outlined" size="small">
              <ListActionButton
                customActionUrl={`/users/${row.id}/show`}
                IconComponent={VisibilityOutlined}
                hideText
                meta={meta}
              />
              <DeleteButton hideText recordItemId={row.id} meta={meta} />
            </ButtonGroup>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 140,
        maxWidth: 180,
      },
      {
        field: "id",
        headerName: translate("users.fields.id"),
        type: "number",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        field: "identityProviderId",
        headerName: translate("users.fields.identityProviderId"),
        minWidth: 350,
      },
      {
        field: "email",
        flex: 1,
        headerName: translate("users.fields.email"),
        maxWidth: 280,
      },
      {
        field: "name",
        flex: 1,
        headerName: translate("users.fields.name"),
        minWidth: 200,
      },
      {
        field: "type",
        flex: 1,
        headerName: translate("users.fields.type"),
        minWidth: 200,
      },
      {
        field: "updatedOn",
        flex: 1,
        headerName: translate("users.fields.updatedOn"),
        maxWidth: 180,
        renderCell: function render({ value }) {
          return <DateField className="DateTimeFullCell" value={value} format={DATE_TIME_FORMAT} />;
        },
      },
    ],
    [translate, meta],
  );

  const isPending = dataGridProps.loading || customerIsLoading;

  return (
    <List breadcrumb={<CustomerBreadcrumbs customer={customer?.data} />}>
      {isPending ? (
        <ContentLoadingIndicator fill />
      ) : (
        <DataGrid {...dataGridProps} loading={isPending} columns={columns} autoHeight forwardedProps={{ meta }} />
      )}
    </List>
  );
};
