import { Autocomplete, Box, TextField } from "@mui/material";
import { IResourceComponentsProps, useResource, useTranslate } from "@refinedev/core";
import { Create, useAutocomplete } from "@refinedev/mui";
import { useForm } from "@refinedev/react-hook-form";
import { useMemo } from "react";
import { Controller } from "react-hook-form";

export const CustomerUserCreate: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { id } = useResource();
  const meta = useMemo(
    () => ({
      customer_id: id,
    }),
    [id],
  );
  const {
    saveButtonProps,
    refineCore: { formLoading },
    control,
    formState: { errors },
  } = useForm({
    refineCoreProps: {
      resource: "customer-users",
      meta,
    },
  });

  const { autocompleteProps: serviceAutocompleteProps } = useAutocomplete({
    resource: "users",
  });

  return (
    <Create isLoading={formLoading} saveButtonProps={saveButtonProps}>
      <Box component="form" sx={{ display: "flex", flexDirection: "column" }} autoComplete="off">
        <Controller
          control={control}
          name="user"
          rules={{ required: "This field is required" }}
          defaultValue={null}
          render={({ field }) => (
            <Autocomplete
              {...serviceAutocompleteProps}
              {...field}
              onChange={(_, value) => {
                field.onChange(value);
              }}
              getOptionKey={(item: any) => {
                return `user-${item.id}`;
              }}
              getOptionLabel={(item) => item.email}
              noOptionsText={"Not selected"}
              filterSelectedOptions
              includeInputInList
              autoComplete
              isOptionEqualToValue={(option, value) => {
                if (value === undefined) {
                  return false;
                }
                if (option.value === '""') {
                  return false;
                }
                return option.id === value.id;
              }}
              renderOption={(props, option) => {
                return (
                  <li {...props}>
                    <div className="UsersAutoCompleteLabel">
                      {option.type === "machine" ? (
                        <strong>{option.name}</strong>
                      ) : (
                        <span>
                          {option.email !== option.name ? (
                            <>
                              {option.email} ({option.name})
                            </>
                          ) : (
                            option.name
                          )}
                        </span>
                      )}
                      <code>#{option.id}</code>
                    </div>
                  </li>
                ) as any;
              }}
              renderInput={(params) => (
                <TextField
                  // sx={{ width: 250 }}
                  {...params}
                  label={translate("customer-users.fields.associatedUser")}
                  margin="normal"
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  error={!!(errors as any)?.title}
                  helperText={(errors as any)?.title?.message}
                  required
                />
              )}
            />
          )}
        />
      </Box>
    </Create>
  );
};
