import { CategoryOutlined, Person2Outlined, PersonAddAltOutlined } from "@mui/icons-material";
import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Action, Authenticated, IResourceItem, Refine } from "@refinedev/core";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";
import { ErrorComponent, RefineSnackbarProvider, ThemedLayoutV2, ThemedTitleV2 } from "@refinedev/mui";
import routerBindings, {
  CatchAllNavigate,
  DocumentTitleHandler,
  NavigateToResource,
  UnsavedChangesNotifier,
} from "@refinedev/react-router";
import { useTranslation } from "react-i18next";
import { Outlet, Route, Routes } from "react-router";

import { permissionManagerApiDataProvider } from "@/Api.dataProvider";
import { useAuthProvider } from "@/App.authProvider";
import { AppIcon } from "@/components/app-icon";
import { Footer } from "@/components/footer";
import { Header } from "@/components/header";
import { ContentLoadingIndicator } from "@/components/information/ContentLoadingIndicator";
import { ColorModeContextProvider } from "@/contexts/color-mode";
import {
  CustomerProductPermissionTemplateCreate,
  CustomerProductPermissionTemplateEdit,
  CustomerProductPermissionTemplateList,
  CustomerProductPermissionTemplateShow,
} from "@/pages/customer-product-permission-templates";
import {
  CustomerProductPermissionCreate,
  CustomerProductPermissionEdit,
  CustomerProductPermissionList,
  CustomerProductPermissionShow,
} from "@/pages/customer-product-permissions";
import {
  CustomerProductRoleCreate,
  CustomerProductRoleEdit,
  CustomerProductRoleList,
  CustomerProductRoleShow,
} from "@/pages/customer-product-roles";
import {
  CustomerProductUserRoleCreate,
  CustomerProductUserRoleEdit,
  CustomerProductUserRoleList,
  CustomerProductUserRoleShow,
} from "@/pages/customer-product-user-roles";
import {
  CustomerProductCreate,
  CustomerProductEdit,
  CustomerProductList,
  CustomerProductShow,
} from "@/pages/customer-products";
import { CustomerUserCreate, CustomerUserList } from "@/pages/customer-users";
import { CustomerCreate, CustomerEdit, CustomerList, CustomerShow } from "@/pages/customers";
import { Login } from "@/pages/login";
import {
  ProductPermissionModelCreate,
  ProductPermissionModelEdit,
  ProductPermissionModelShow,
} from "@/pages/product-permission-models";
import { ProductCreate, ProductEdit, ProductList, ProductShow } from "@/pages/products";
import { RoleCreate, RoleEdit, RoleList, RoleShow } from "@/pages/roles";
import { UserList, UserShow } from "@/pages/users";

import "./App.css";

function App() {
  const { t, i18n } = useTranslation();
  const { isLoading, authProvider } = useAuthProvider();
  if (isLoading) {
    return <ContentLoadingIndicator fill />;
  }

  const customTitleHandler = ({
    autoGeneratedTitle,
  }: {
    resource?: IResourceItem;
    action?: Action;
    params?: Record<string, string | undefined>;
    pathname?: string;
    autoGeneratedTitle: string;
  }) => {
    // const title = "Custom default";
    // console.debug({ resource, action, params, autoGeneratedTitle });
    return autoGeneratedTitle;
  };

  const i18nProvider = {
    translate: (key: string, params: object) => t(key as any, params as any) as any,
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <RefineKbarProvider>
        <ColorModeContextProvider>
          <CssBaseline />
          <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
          <RefineSnackbarProvider>
            <Refine
              dataProvider={permissionManagerApiDataProvider}
              authProvider={authProvider}
              i18nProvider={i18nProvider}
              routerProvider={routerBindings}
              resources={[
                {
                  name: "customers",
                  list: "/customers",
                  create: "/customers/create",
                  edit: "/customers/:id/edit",
                  show: "/customers/:id/show",
                  meta: {
                    canDelete: true,
                  },
                },
                {
                  name: "customer-users",
                  list: "/customers/:id/users",
                  create: "/customers/:id/users/create",
                  meta: {
                    canDelete: true,
                    icon: <Person2Outlined />,
                    hide: true,
                  },
                },
                {
                  name: "customer-products",
                  list: "/customers/:id/products",
                  create: "/customers/:id/products/create",
                  edit: "/customers/:customer_id/products/:id/edit",
                  show: "/customers/:customer_id/products/:id/show",
                  meta: {
                    canDelete: true,
                  },
                  options: {
                    hide: true,
                  },
                },
                {
                  name: "customer-product-permissions",
                  list: "/customers/:customer_id/products/:product_id/permissions",
                  create: "/customers/:customer_id/products/:product_id/permissions/create",
                  edit: "/customers/:customer_id/products/:product_id/permissions/:id/edit",
                  show: "/customers/:customer_id/products/:product_id/permissions/:id/show",
                  meta: {
                    canDelete: true,
                  },
                  options: {
                    hide: true,
                  },
                },
                {
                  name: "customer-product-permission-templates",
                  list: "/customers/:customer_id/products/:product_id/permission-templates",
                  create: "/customers/:customer_id/products/:product_id/permission-templates/create",
                  edit: "/customers/:customer_id/products/:product_id/permission-templates/:id/edit",
                  show: "/customers/:customer_id/products/:product_id/permission-templates/:id/show",
                  meta: {
                    canDelete: true,
                  },
                  options: {
                    hide: true,
                  },
                },
                {
                  name: "customer-product-roles",
                  list: "/customers/:customer_id/products/:product_id/roles",
                  create: "/customers/:customer_id/products/:product_id/roles/create",
                  edit: "/customers/:customer_id/products/:product_id/roles/:id/edit",
                  show: "/customers/:customer_id/products/:product_id/roles/:id/show",
                  meta: {
                    canDelete: true,
                  },
                  options: {
                    hide: true,
                  },
                },
                {
                  name: "customer-product-user-roles",
                  list: "/customers/:customer_id/products/:product_id/user-roles",
                  create: "/customers/:customer_id/products/:product_id/user-roles/create",
                  edit: "/customers/:customer_id/products/:product_id/users/:user_id/roles/:id/edit",
                  show: "/customers/:customer_id/products/:product_id/users/:user_id/roles/:id/show",
                  meta: {
                    canDelete: true,
                  },
                  options: {
                    hide: true,
                  },
                },
                {
                  name: "product-permission-model",
                  create: "/products/:id/permission-model/create",
                  edit: "/products/:id/permission-model/edit",
                  show: "/products/:id/permission-model",
                  meta: {
                    canDelete: false,
                  },
                  options: {
                    hide: true,
                  },
                },
                {
                  name: "products",
                  list: "/products",
                  create: "/products/create",
                  edit: "/products/:id/edit",
                  show: "/products/:id/show",
                  meta: {
                    canDelete: true,
                    icon: <CategoryOutlined />,
                  },
                },
                {
                  name: "roles",
                  list: "/roles",
                  show: "/roles/:id/show",
                  meta: {
                    canEdit: false,
                    canDelete: false,
                    icon: <PersonAddAltOutlined />,
                  },
                },
                {
                  name: "users",
                  list: "/users",
                  create: "/users/create",
                  edit: "/users/:id/edit",
                  show: "/users/:id/show",
                  meta: {
                    canDelete: true,
                    icon: <Person2Outlined />,
                  },
                },
              ]}
              options={{
                syncWithLocation: true,
                warnWhenUnsavedChanges: true,
                useNewQueryKeys: true,
                disableTelemetry: true,
              }}
            >
              <Routes>
                <Route
                  element={
                    <Authenticated
                      key="authenticated-inner"
                      fallback={<CatchAllNavigate to="/login" />}
                      loading={<ContentLoadingIndicator fill />}
                    >
                      <ThemedLayoutV2
                        Header={() => <Header sticky />}
                        Footer={() => <Footer />}
                        Title={({ collapsed }) => (
                          <ThemedTitleV2
                            collapsed={collapsed}
                            text={t("product.sidebar.title", "Resources")}
                            icon={<AppIcon />}
                          />
                        )}
                      >
                        <Outlet />
                      </ThemedLayoutV2>
                    </Authenticated>
                  }
                >
                  <Route index element={<NavigateToResource resource="customers" />} />
                  <Route path="/customers">
                    <Route index element={<CustomerList />} />
                    <Route path="create" element={<CustomerCreate />} />
                    <Route path=":id/edit" element={<CustomerEdit />} />
                    <Route path=":id/show" element={<CustomerShow />} />
                    <Route path=":id/products">
                      <Route index element={<CustomerProductList />} />
                      <Route path="create" element={<CustomerProductCreate />} />
                      <Route path=":id/edit" element={<CustomerProductEdit />} />
                      <Route path=":id/show" element={<CustomerProductShow />} />
                      <Route path=":id/permissions">
                        <Route index element={<CustomerProductPermissionList />} />
                        <Route path="create" element={<CustomerProductPermissionCreate />} />
                        <Route path=":id/edit" element={<CustomerProductPermissionEdit />} />
                        <Route path=":id/show" element={<CustomerProductPermissionShow />} />
                      </Route>
                      <Route path=":id/permission-templates">
                        <Route index element={<CustomerProductPermissionTemplateList />} />
                        <Route path="create" element={<CustomerProductPermissionTemplateCreate />} />
                        <Route path=":id/edit" element={<CustomerProductPermissionTemplateEdit />} />
                        <Route path=":id/show" element={<CustomerProductPermissionTemplateShow />} />
                      </Route>
                      <Route path=":id/roles">
                        <Route index element={<CustomerProductRoleList />} />
                        <Route path="create" element={<CustomerProductRoleCreate />} />
                        <Route path=":id/edit" element={<CustomerProductRoleEdit />} />
                        <Route path=":id/show" element={<CustomerProductRoleShow />} />
                      </Route>
                      <Route path=":id/user-roles">
                        <Route index element={<CustomerProductUserRoleList />} />
                        <Route path="create" element={<CustomerProductUserRoleCreate />} />
                        <Route path=":id/edit" element={<CustomerProductUserRoleEdit />} />
                      </Route>
                      <Route path=":id/users">
                        <Route path=":id/roles">
                          <Route path=":id/show" element={<CustomerProductUserRoleShow />} />
                        </Route>
                      </Route>
                    </Route>
                    <Route path=":id/users">
                      <Route index element={<CustomerUserList />} />
                      <Route path="create" element={<CustomerUserCreate />} />
                    </Route>
                  </Route>
                  <Route path="/products">
                    <Route index element={<ProductList />} />
                    <Route path="create" element={<ProductCreate />} />
                    <Route path=":id/edit" element={<ProductEdit />} />
                    <Route path=":id/show" element={<ProductShow />} />
                    <Route path=":id/permission-model">
                      <Route path="create" element={<ProductPermissionModelCreate />} />
                      <Route path="edit" element={<ProductPermissionModelEdit />} />
                      <Route index element={<ProductPermissionModelShow />} />
                    </Route>
                  </Route>
                  <Route path="/roles">
                    <Route index element={<RoleList />} />
                    <Route path="create" element={<RoleCreate />} />
                    <Route path=":id/edit" element={<RoleEdit />} />
                    <Route path=":id/show" element={<RoleShow />} />
                  </Route>
                  <Route path="/users">
                    <Route index element={<UserList />} />
                    <Route path=":id/show" element={<UserShow />} />
                  </Route>
                  <Route path="*" element={<ErrorComponent />} />
                </Route>
                <Route
                  element={
                    <Authenticated key="authenticated-outer" fallback={<Outlet />}>
                      <NavigateToResource />
                    </Authenticated>
                  }
                >
                  <Route path="/login" element={<Login />} />
                </Route>
              </Routes>

              <RefineKbar />
              <UnsavedChangesNotifier />
              <DocumentTitleHandler handler={customTitleHandler} />
            </Refine>
          </RefineSnackbarProvider>
        </ColorModeContextProvider>
      </RefineKbarProvider>
    </LocalizationProvider>
  );
}

export default App;
