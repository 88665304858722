import Stack from "@mui/material/Stack";
import { useTranslate } from "@refinedev/core";
import React, { useContext } from "react";

import { ColorModeContext } from "@/contexts/color-mode";

import "./index.css";

export interface FooterProps {
  children?: React.ReactNode;
}

export const Footer: React.FC<FooterProps> = ({ children }) => {
  const t = useTranslate();
  const year = new Date().getFullYear();
  const { mode } = useContext(ColorModeContext);
  return (
    <div className="AppFooter" data-theme={mode === "dark" ? "dark" : "light"}>
      <Stack direction="row" width="100%" justifyContent="flex-end" alignItems="center" gap="16px">
        <div className="AppVendorInfo">
          &copy;&nbsp;<span>{year}</span>&nbsp;-&nbsp;Sportradar
        </div>
        <div className="AppVendorLinks">
          <ul>
            <li>
              <a target="_blank" href="http://synergysports.com/contact/" rel="noreferrer">
                {t("product.user-note.support", "Support")}
              </a>
            </li>
            <li>
              <a target="_blank" href="https://sportradar.com/single-sign-on-sso-privacy-notice/" rel="noreferrer">
                {t("product.user-note.privacy", "Privacy")}
              </a>
            </li>
            <li>
              <a target="_blank" href="http://synergysports.com/terms/" rel="noreferrer">
                {t("product.user-note.terms", "Terms")}
              </a>
            </li>
          </ul>
        </div>
        {children}
      </Stack>
    </div>
  );
};
