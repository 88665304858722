import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import "@/i18n";

import App from "@/App";
import { Tracing } from "@/App.tracing";
import { AUTH0_CLIENT_ID, AUTH0_DOMAIN, CSPM_API_BASE_URL, ENVIRONMENT, PROJECT_VERSION } from "@/Environment";
import { ContentLoadingIndicator } from "@/components/information/ContentLoadingIndicator";
import "./index.css";

dayjs.extend(relativeTime);

function renderApplication() {
  const domNode = document.getElementById("root") as HTMLElement;
  const root = createRoot(domNode);
  root.render(
    <BrowserRouter>
      <React.Suspense fallback={<ContentLoadingIndicator fill />}>
        <Auth0Provider
          domain={AUTH0_DOMAIN}
          clientId={AUTH0_CLIENT_ID}
          authorizationParams={{ redirect_uri: window.location.origin }}
        >
          <App />
        </Auth0Provider>
      </React.Suspense>
    </BrowserRouter>,
  );
}

function entryPoint() {
  try {
    console.debug("Starting application", { ENVIRONMENT, VERSION: PROJECT_VERSION, API: CSPM_API_BASE_URL });
    Tracing.init();
    renderApplication();
  } catch (error) {
    console.error("Application bootstrap error", error);
    Tracing.capture(error);
  }
}

entryPoint();
