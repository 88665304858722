import { Box, TextField } from "@mui/material";
import { IResourceComponentsProps, useOne, useResource, useTranslate } from "@refinedev/core";
import { Edit, RefreshButton } from "@refinedev/mui";
import { useForm } from "@refinedev/react-hook-form";
import { useMemo } from "react";
import { useMatch } from "react-router";

import { CustomerProductsBreadcrumbs } from "@/components/breadcrumbs";
import { ContentLoadingIndicator } from "@/components/information/ContentLoadingIndicator";

export const CustomerProductRoleEdit: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();

  const { resource } = useResource();
  const match = useMatch(`${resource?.edit}`);
  const customer_id = match?.params.customer_id ?? "";
  const product_id = match?.params.product_id ?? "";
  const meta = useMemo(
    () => ({
      customer_id,
      product_id,
    }),
    [customer_id, product_id],
  );

  const { data: customer, isLoading: customerIsLoading } = useOne({
    resource: "customers",
    id: customer_id,
  });

  const { data: product, isLoading: productIsLoading } = useOne({
    resource: "products",
    id: product_id,
  });

  const {
    saveButtonProps,
    refineCore: { queryResult },
    register,
    formState: { errors },
  } = useForm({
    refineCoreProps: {
      meta,
    },
  });

  const isPending = queryResult?.isLoading || customerIsLoading || productIsLoading;

  return (
    <Edit
      isLoading={isPending}
      breadcrumb={
        <CustomerProductsBreadcrumbs
          customer={customer?.data}
          product={product?.data}
          lastPaths={[
            {
              id: "customer-product-roles",
              path: `/customers/${customer?.data.id}/products/${product?.data.id}/roles`,
              title: translate("roles.roles", "Roles"),
            },
          ]}
        />
      }
      saveButtonProps={saveButtonProps}
      headerButtons={({ refreshButtonProps }) => (
        <Box display="flex" flexDirection="row" alignItems="center">
          <RefreshButton {...refreshButtonProps} meta={meta} />
        </Box>
      )}
    >
      {isPending ? (
        <ContentLoadingIndicator fill />
      ) : (
        <Box component="form" sx={{ display: "flex", flexDirection: "column" }} autoComplete="off">
          <TextField
            {...register("id", {
              required: "This field is required",
              valueAsNumber: true,
            })}
            error={!!(errors as any)?.id}
            helperText={(errors as any)?.id?.message}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="number"
            label={translate("roles.fields.id")}
            name="id"
            disabled
          />
          <TextField
            {...register("name", {
              required: "This field is required",
            })}
            error={!!(errors as any)?.name}
            helperText={(errors as any)?.name?.message}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="text"
            label={translate("roles.fields.name")}
            name="name"
          />
          <TextField
            {...register("description", {
              required: "This field is required",
            })}
            error={!!(errors as any)?.description}
            helperText={(errors as any)?.description?.message}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="text"
            label={translate("roles.fields.description")}
            name="description"
          />
        </Box>
      )}
    </Edit>
  );
};
