import { ButtonGroup } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { IResourceComponentsProps, useOne, useResource, useTranslate } from "@refinedev/core";
import { DateField, DeleteButton, EditButton, List, MarkdownField, ShowButton, useDataGrid } from "@refinedev/mui";
import React, { useMemo } from "react";
import { useMatch } from "react-router";

import { DATE_TIME_FORMAT } from "@/Environment";
import { CustomerProductsBreadcrumbs } from "@/components/breadcrumbs";
import { ContentLoadingIndicator } from "@/components/information/ContentLoadingIndicator";

export const CustomerProductRoleList: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();

  const { resource } = useResource();
  const match = useMatch(`${resource?.list}`);
  const customer_id = match?.params.customer_id ?? "";
  const product_id = match?.params.product_id ?? "";
  const meta = useMemo(
    () => ({
      customer_id,
      product_id,
    }),
    [customer_id, product_id],
  );

  const { data: customer, isLoading: customerIsLoading } = useOne({
    resource: "customers",
    id: customer_id,
  });

  const { data: product, isLoading: productIsLoading } = useOne({
    resource: "products",
    id: product_id,
  });

  const { dataGridProps } = useDataGrid({
    meta,
  });

  const columns = React.useMemo<GridColDef[]>(
    () => [
      {
        field: "actions",
        headerName: translate("table.actions"),
        sortable: false,
        renderCell: function render({ row }) {
          return (
            <ButtonGroup className="ListActions" color="info" variant="outlined" size="small">
              <EditButton hideText recordItemId={row.id} meta={meta} />
              <ShowButton hideText recordItemId={row.id} meta={meta} />
              <DeleteButton hideText recordItemId={row.id} meta={meta} />
            </ButtonGroup>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 140,
        maxWidth: 180,
      },
      {
        field: "id",
        headerName: translate("roles.fields.id"),
        type: "number",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        field: "name",
        flex: 1,
        headerName: translate("roles.fields.name"),
        minWidth: 220,
      },
      {
        field: "description",
        flex: 1,
        headerName: translate("roles.fields.description"),
        minWidth: 480,
        sortable: false,
        renderCell: function render({ value }) {
          return <MarkdownField value={(value ?? "").slice(0, 80) + "..."} />;
        },
      },
      {
        field: "updatedOn",
        flex: 1,
        headerName: translate("roles.fields.updatedOn"),
        maxWidth: 180,
        renderCell: function render({ value }) {
          return <DateField className="DateTimeFullCell" value={value} format={DATE_TIME_FORMAT} />;
        },
      },
    ],
    [translate, meta],
  );

  const isPending = dataGridProps.loading || customerIsLoading || productIsLoading;

  return (
    <List
      createButtonProps={{ meta }}
      breadcrumb={<CustomerProductsBreadcrumbs customer={customer?.data} product={product?.data} />}
    >
      {isPending ? (
        <ContentLoadingIndicator fill />
      ) : (
        <DataGrid {...dataGridProps} loading={isPending} columns={columns} autoHeight forwardedProps={{ meta }} />
      )}
    </List>
  );
};
