import { Breadcrumbs } from "@mui/material";
import Link from "@mui/material/Link";
import { useLink, useTranslate } from "@refinedev/core";

export interface CustomerBreadcrumbsProps {
  customer: any;
  lastPaths?: {
    id: string;
    path: string;
    title: string;
  }[];
}

export const CustomerBreadcrumbs: React.FC<CustomerBreadcrumbsProps> = ({ customer, lastPaths }) => {
  const translate = useTranslate();
  const NewLink = useLink();
  const routes = [
    {
      id: "customers-list",
      path: "/customers",
      title: translate("customers.customers", "Customers"),
    },
    {
      id: "customer-show",
      path: `/customers/${customer?.id}/show`,
      title: customer?.displayName,
    },
  ];
  if (lastPaths) {
    routes.push(...lastPaths);
  }
  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      sx={{
        paddingY: 2,
        paddingX: 2,
      }}
    >
      {routes.map((r) => {
        return (
          <Link
            key={r.id}
            component={NewLink as any}
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: "14px",
            }}
            variant="subtitle1"
            marginLeft={0.5}
            underline="hover"
            color="inherit"
            to={r.path}
          >
            {r.title}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export interface CustomerProductsBreadcrumbsProps {
  customer: any;
  product: any;
  lastPaths?: {
    id: string;
    path: string;
    title: string;
  }[];
}

export const CustomerProductsBreadcrumbs: React.FC<CustomerProductsBreadcrumbsProps> = ({
  customer,
  product,
  lastPaths,
}) => {
  const translate = useTranslate();
  const NewLink = useLink();
  const routes = [
    {
      id: "customers-list",
      path: "/customers",
      title: translate("customers.customers", "Customers"),
    },
    {
      id: "customer-show",
      path: `/customers/${customer?.id}/show`,
      title: customer?.displayName,
    },
    {
      id: "products-list",
      path: `/customers/${customer?.id}/products`,
      title: translate("products.products", "Products"),
    },
    {
      id: "product-show",
      path: `/customers/${customer?.id}/products/${product?.id}/show`,
      title: product?.displayName,
    },
  ];
  if (lastPaths) {
    routes.push(...lastPaths);
  }
  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      sx={{
        paddingY: 2,
        paddingX: 2,
      }}
    >
      {routes.map((r) => {
        return (
          <Link
            component={NewLink as any}
            key={r.id}
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: "14px",
            }}
            variant="subtitle1"
            marginLeft={0.5}
            underline="hover"
            color="inherit"
            to={r.path}
          >
            {r.title}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};
