import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { IResourceComponentsProps, useOne, useResource, useTranslate } from "@refinedev/core";
import { DateField, DeleteButton, EditButton, List, MarkdownField, ShowButton, useDataGrid } from "@refinedev/mui";
import React, { useMemo } from "react";
import { NavLink } from "react-router";

import { DATE_TIME_FORMAT } from "@/Environment";
import { CustomerBreadcrumbs } from "@/components/breadcrumbs";
import { ContentLoadingIndicator } from "@/components/information/ContentLoadingIndicator";

export default function ActionsMenu({ row }: { row: any }) {
  const resource = useResource();

  const translate = useTranslate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="actions-menu-button"
        aria-controls={open ? "actions-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        size="small"
      >
        <ArrowDropDownIcon fontSize="small" />
      </Button>
      <Menu
        id="actions-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "actions-menu-button",
        }}
      >
        <MenuItem onClick={handleClose} component={NavLink} to={`/customers/${resource.id}/products/${row.id}/roles`}>
          {translate("actions-menu.roles", "Roles")}
        </MenuItem>
        <MenuItem
          onClick={handleClose}
          component={NavLink}
          to={`/customers/${resource.id}/products/${row.id}/user-roles`}
        >
          {translate("actions-menu.user-roles", "User roles")}
        </MenuItem>
        <MenuItem
          onClick={handleClose}
          component={NavLink}
          to={`/customers/${resource.id}/products/${row.id}/permissions`}
        >
          {translate("actions-menu.permissions", "Permissions")}
        </MenuItem>
        <MenuItem
          onClick={handleClose}
          component={NavLink}
          to={`/customers/${resource.id}/products/${row.id}/permission-templates`}
        >
          {translate("actions-menu.permission-templates", "Permission templates")}
        </MenuItem>
      </Menu>
    </div>
  );
}

export const CustomerProductList: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();

  const { dataGridProps } = useDataGrid();
  const { id } = useResource();

  const { data: customer, isLoading: customerIsLoading } = useOne({
    resource: "customers",
    id,
  });

  const meta = useMemo(
    () => ({
      id,
      customer_id: id,
    }),
    [id],
  );

  const columns = React.useMemo<GridColDef[]>(
    () => [
      {
        field: "actions",
        headerName: translate("table.actions"),
        sortable: false,
        renderCell: function render({ row }) {
          return (
            <ButtonGroup className="ListActions" color="info" variant="outlined" size="small">
              <EditButton hideText recordItemId={row.id} meta={{ customer_id: id }} />
              <ShowButton hideText recordItemId={row.id} meta={{ customer_id: id }} />
              <DeleteButton hideText recordItemId={row.id} meta={{ customer_id: id }} />
              <ActionsMenu row={row} />
            </ButtonGroup>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 200,
        maxWidth: 200,
      },
      {
        field: "id",
        headerName: translate("products.fields.id"),
        type: "number",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        field: "code",
        flex: 1,
        headerName: translate("products.fields.code"),
        maxWidth: 200,
      },
      {
        field: "displayName",
        flex: 1,
        headerName: translate("products.fields.displayName"),
        minWidth: 200,
      },
      {
        field: "description",
        flex: 1,
        headerName: translate("products.fields.description"),
        minWidth: 250,
        sortable: false,
        renderCell: function render({ value }) {
          return <MarkdownField value={(value ?? "").slice(0, 80) + "..."} />;
        },
      },
      {
        field: "updatedOn",
        flex: 1,
        headerName: translate("products.fields.updatedOn"),
        maxWidth: 180,
        renderCell: function render({ value }) {
          return <DateField className="DateTimeFullCell" value={value} format={DATE_TIME_FORMAT} />;
        },
      },
    ],
    [translate, id],
  );

  const isPending = dataGridProps.loading || customerIsLoading;

  return (
    <List breadcrumb={<CustomerBreadcrumbs customer={customer?.data} />}>
      {isPending ? (
        <ContentLoadingIndicator fill />
      ) : (
        <DataGrid {...dataGridProps} loading={isPending} columns={columns} autoHeight forwardedProps={{ meta }} />
      )}
    </List>
  );
};
